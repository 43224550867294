<template>
  <div :class="['c-lesson', `c-lesson--${unitSlug}`]">
    <FHBreadcrumbs
      v-if="crumbs"
      class="c-lesson__breadcrumbs u-py u-px"
      :crumbs="crumbs"
    />
    <Banner
      v-if="cover"
      :image-url="cover.imageURL"
      :image-alt="cover.altText"
      :image-credit="cover.imageCredit"
      :image-meta="cover.file"
      :figcaption="cover.caption"
    />
    <ContentLoader v-else height="150">
      <rect x="0" y="0" width="100%" height="100%"></rect>
    </ContentLoader>
    <div v-if="title" class="c-lesson__container u-mb">
      <div class="o-wrapper u-px">
        <VHeading header="h1" class="o-heading">
          {{ title }}
        </VHeading>
        <AudioElement v-if="audio && audio.audio_type" :content="audio" />
      </div>
    </div>

    <FHTabs
      v-if="articles.length > 0"
      ref="tabs"
      :tabable="true"
      :initial-tab="initTab"
      @changed="onChangeTab"
    >
      <template #before-tablist>
        <div class="c-tabs__tablist-label">{{ $t('LESSON_TABS_LABEL') }}:</div>
      </template>
      <FHTab :name="$t('ARTICLE_SUMMARY')">
        <VHeading header="h2" class="o-heading o-wrapper">
          {{ $t('ARTICLE_SUMMARY') }}
        </VHeading>
        <div class="o-wrapper o-description" v-md-html="description" />
      </FHTab>
      <FHTab
        v-for="article in articles"
        :key="article._id"
        :name="article.name"
      >
        <ArticleElement :article="article.article_type" />
      </FHTab>
    </FHTabs>
    <Modal />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import { fetchLesson, fetchUnitBySlug } from '@/services/strapi'
import { parseLearningElements } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import { FHTabs, FHTab } from '@forlagshuset/v-tabs'

import Banner from '@/components/Banner'
import ArticleElement from '@/components/ArticleWrapper'
import AudioElement from '@/components/AudioElement'
import Modal from '@/components/Modal'

export default {
  name: 'Lesson',

  components: {
    ContentLoader,
    Banner,
    VHeading,
    FHBreadcrumbs,
    FHTabs,
    FHTab,
    ArticleElement,
    AudioElement,
    Modal,
  },

  setup(props, ctx) {
    const unitSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )
    const lessonSlug = computed(
      () => ctx.root.$options.router.history.current.params.lessonSlug,
    )
    const title = ref('')
    const lesson = ref({})
    const cover = ref(null)
    const description = ref('')
    const audio = ref({})
    const articles = ref([])
    const crumbs = ref([])
    const onChangeTab = ref(() => {})
    const tabs = ref(null)
    const initTab = ref(ctx.root.$route.query.tab)

    onChangeTab.value = selectedTab => {
      if (selectedTab.tab.$el.id !== ctx.root.$route.query.tab) {
        if (ctx.root.$route.query.tab) {
          ctx.root.$router.push({ query: { tab: selectedTab.tab.$el.id } })
        } else {
          ctx.root.$router.replace({ query: { tab: selectedTab.tab.$el.id } })
        }
        if (tabs.value) {
          tabs.value.$el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    onMounted(async () => {
      const response = await fetchLesson(lessonSlug.value)
      lesson.value = response

      title.value = response.name
      description.value = response.description

      articles.value = parseLearningElements(response.learningElementSection)

      if (lesson.value.cover && lesson.value.cover.image_type) {
        cover.value = {}
        cover.value.imageURL = lesson.value.cover.image_type.file.url
        cover.value.altText = lesson.value.cover.image_type.altText
        cover.value.imageCredit = lesson.value.cover.image_type.license
          ? lesson.value.cover.image_type.license.credit
          : ''
        cover.value.file = lesson.value.cover.image_type.file
        cover.value.caption = lesson.value.cover.image_type.caption
      }

      const unit = await fetchUnitBySlug(unitSlug.value)
      if (unit.name && unit.slug) {
        if (unit.slug === 'aktor-tema') {
          crumbs.value.push({
            type: 'search',
            name: unit.name,
            slug: '?tab=temaer',
          })
        } else {
          crumbs.value.push({
            type: 'unit',
            name: unit.name,
            slug: unit.slug,
          })
        }
      }

      crumbs.value.push({
        type: '',
        name: lesson.value.name,
        slug: lesson.value.slug,
      })

      watch(
        () => ctx.root.$route.query.tab,
        (val, prev) => {
          if (ctx.refs.tabs && val !== prev) {
            ctx.refs.tabs.setActive(`#${val}`)
          }
        },
      )
    })

    return {
      unitSlug,
      lessonSlug,
      lesson,
      cover,
      title,
      audio,
      description,
      articles,
      crumbs,
      onChangeTab,
      tabs,
      initTab,
    }
  },
}
</script>

<style lang="scss">
.c-lesson,
[class*='c-lesson--aktor-tema'] {
  min-height: calc(100vh - 14rem);
  .c-tabs__btn {
    &--active {
      background-color: $color-brand;
      border-color: $color-brand;
      &:before {
        background-color: $color-brand;
      }
    }
    &:hover {
      background-color: $color-brand;
      border-color: $color-brand;
      color: $color-white;
    }
  }
}

.c-lesson__breadcrumbs {
  font-family: CircularPro, sans-serif;
  .c-breadcrumbs__home {
    display: none;
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
